<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-row :gutter="24" style="margin-left: 10%;margin-right: 10%;">
        <a-col :span="24">
          <a-form-item label="服务器">
            <a-input v-model:value="sendEmailParams.host" placeholder="smtp.*.com" :allowClear="true" style="width: 30%;" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="发件人">
            <a-input v-model:value="sendEmailParams.sender" placeholder="邮箱" :allowClear="true" style="width: 30%;" />
            <a-input-password type="password" v-model:value="sendEmailParams.password" placeholder="邮箱smtp服务密码" :allowClear="true" style="width: 30%;" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="收件人">
            <a-input v-model:value="sendEmailParams.addresseeArr[0]" placeholder="邮箱" :allowClear="true" style="width: 30%;" />
            <a-button type="primary" @click="plus">+</a-button>
          </a-form-item>
        </a-col>
        <a-col :span="24" v-if="addresseeCount > 0">
          <a-form-item label="">
            <span v-for="(count,index) in addresseeCount" :key="index">
              <a-input v-model:value="sendEmailParams.addresseeArr[count]" placeholder="邮箱" :allowClear="true" style="width: 30%;" />
              <a-button type="default" @click="minus(count)">-</a-button>
            </span>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="自定义">
            <a-date-picker v-model:value="sendEmailParams.customTimeStr" placeholder="时间" show-time valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 30%;" />
            <a-input v-model:value="sendEmailParams.senderName" placeholder="昵称" :allowClear="true" style="width: 30%;" />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item label="主题">
            <a-input v-model:value="sendEmailParams.title" placeholder="" :allowClear="true" style="width: 100%;" />
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item label="正文">
            <QuillEditor
                    theme="snow"
                    toolbar="full"
                    contentType="html"
                    v-model:content="sendEmailParams.content"
                    style="width: 100%;height: 40vh;"
            />
          </a-form-item>
        </a-col>

      </a-row>

      <a-row>
        <a-col :span="24" style="text-align: center">
          <a-button type="primary" @click="sendEmail">发送</a-button>
        </a-col>
      </a-row>
    </a-card>

  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import * as EmailApi from '@/api/EmailApi.js';
import {message} from "ant-design-vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import ImageUploader from 'quill-image-uploader';

export default {
  name: 'SendEmail',
  components: {
    MainPanel,
    QuillEditor
  },
  data() {
    return {
      sendEmailParams: {
        addresseeArr: [],
        content: '',
        customTimeStr: '',
        host: '',
        password: '',
        sender: '',
        senderName: '',
        title: ''
      },

      addresseeCount: 0,

    }
  },

  methods: {
    plus() {
      ++this.addresseeCount;
    },

    minus(count) {
      this.sendEmailParams.addresseeArr.splice(count, 1);
      --this.addresseeCount;
    },

    sendEmail() {
      EmailApi['sendEmailApi'](this.sendEmailParams)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 1) {
          message.success(msg);
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })
    },
  },

  created() {

  },

  mounted() {

  }
}
</script>
